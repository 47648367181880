/* Dashboard.css */

.dashboard-container {
    text-align: center;
    padding: 40px;
  }
  
  h1 {
    font-size: 1.8em;
    color: #333;
  }
  
  p {
    color: #555;
  }
  
  .role-card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .role-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    text-align: center;
  }
  
  .role-card h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .role-card p {
    color: #666;
    font-size: 0.9em;
    margin-bottom: 15px;
  }
  
  .role-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .btn {
    background-color: #17a2b8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .btn:hover {
    background-color: #138496;
  }
  