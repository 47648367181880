.nav-link.active {
    background-color: #2c3e50; /* Warna background saat aktif */
    color: #fff !important; /* Warna teks saat aktif */
    font-weight: bold; /* Memberikan penekanan pada teks */
    border-left: 4px solid #3498db; /* Garis di kiri sebagai indikator */
  }
  
  .nav-link {
    color: #b0bec5; /* Warna default teks */
  }
  
  .nav-link:hover {
    color: #eceff1; /* Warna teks saat di-hover */
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .sidebar-brand {
    display: flex;
    align-items: center;
  }
  
  .btn-logout {
    margin-right: 10px; /* Tambahkan margin jika perlu */
  }
  