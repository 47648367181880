/* FormLogin.css */

body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(270deg, #6a11cb, #2575fc, #6a11cb);
    background-size: 600% 600%; /* Larger background size to create a smooth animation */
    animation: gradientAnimation 10s ease infinite; /* 10s animation loop */
  }
  
  .login-card {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .login-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .login-form .form-group label {
    display: block;
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
  }
  
  .login-form .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .login-form .form-control:focus {
    border-color: #2575fc;
    outline: none;
    box-shadow: 0 0 5px rgba(37, 117, 252, 0.5);
  }
  
  .btn.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #2575fc;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn.login-button:hover {
    background-color: #1e63d1;
  }
  
  .btn.login-button svg {
    margin-right: 5px;
  }
  